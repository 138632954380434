import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { SkeletonLoader } from '~anyx/shared/ui';
import { DateUtils, NumberUtils } from '~anyx/shared/utils';

import { RatingComment } from '../rating-comment';

import { useGradientScroll } from './hooks';

interface CommentListProps {
  reviews: readonly {
    comment: string;
    date: string;
    rating: string;
  }[];
  loading: boolean;
  className?: string;
}

export const CommentList = ({ reviews, loading, className }: CommentListProps) => {
  const { t } = useTranslation('report');
  const { showGradient, scrollContainerRef } = useGradientScroll();

  return (
    <div
      ref={scrollContainerRef}
      className={classNames('flex flex-col gap-4 p-4 md:overflow-y-auto', className)}
    >
      {loading ? (
        Array.from({ length: 10 }).map((_, i) => (
          <div key={i} className="shadow-card2 flex flex-col gap-4 rounded-lg p-4">
            <div className="flex items-center gap-4">
              <SkeletonLoader loading className="w-[4.375rem]" />
              <SkeletonLoader loading className="w-20" />
            </div>
            <div className="flex flex-col gap-2">
              <SkeletonLoader loading />
              <SkeletonLoader loading className="w-1/2" />
            </div>
          </div>
        ))
      ) : reviews.length > 0 ? (
        reviews.map((review, index) => (
          <RatingComment
            key={`${review.date}-${index}`}
            averageRating={NumberUtils.toNumber(review.rating)}
            title={DateUtils.formatDate(review.date, DateUtils.FORMATS.P)}
            content={review.comment}
          />
        ))
      ) : (
        <p className="text-sm">{t('report.page.product.noReviews', { ns: 'report' })}</p>
      )}
      <p className="text-gray-7 text-2xs">
        {t('report.page.product.reviewListingDescription', { ns: 'report' })}
      </p>
      {showGradient && (
        <div className="bg-bottomGradient absolute bottom-0 left-0 right-0 h-24 border-0" />
      )}
    </div>
  );
};

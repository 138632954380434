import { useMemo } from 'react';

import { useWorkspaceCtx, WorkspaceMode } from '~anyx/common/workspace';
import { useFeatureFlag } from '~anyx/external/firebase';
export const useAiChatFeatureFlag = (): boolean => {
  const { workspace, mode } = useWorkspaceCtx();
  const rpAiChatValueActiveFlag = useFeatureFlag('rp_ai_chat_active', { as: 'string' });
  const isAiChatPageActive = useMemo(() => {
    const flagValue = JSON.parse(rpAiChatValueActiveFlag?.value || '{}');
    return flagValue?.allowedStoreIds?.includes(workspace?.storeId);
  }, [rpAiChatValueActiveFlag?.value, workspace?.storeId]);
  return mode === WorkspaceMode.ON && isAiChatPageActive;
};

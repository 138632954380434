import { RatingStar } from '../rating-star';

interface RatingCommentProps {
  averageRating: number;
  title: string;
  content: string;
}

export const RatingComment = ({ averageRating, title, content }: RatingCommentProps) => {
  return (
    <div className="shadow-card2 flex flex-col gap-4 rounded-lg p-4">
      <div className="flex items-center gap-4">
        <RatingStar averageRating={averageRating} size="sm" />
        <p className="text-2xs font-semibold leading-7 text-gray-500">{title}</p>
      </div>
      <p className="text-sm leading-9">{content}</p>
    </div>
  );
};

import { ProductRoutePath } from '~anyx/common/routing';
import { BoxIcon } from '~anyx/shared/icons';
import { RouteLink, RouteLinks } from '~anyx/shared/utils';

import { LinkAdapter } from '../types';

export type ProductLinksProps = {
  canUseProduct: boolean;
  storeId?: string;
  // TODO: Clean up this once finished demo.
  isDisabled?: boolean;
};

const buildProductLinkProperties = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.product',
    options: { count: 2, ns: 'shared' },
  },
  icon: (props) => <BoxIcon {...props} />,
  path: ProductRoutePath({ workspaceStoreId: storeId }).list().path,
  activePath: ProductRoutePath({ workspaceStoreId: null }).root().pattern,
});

const getProductLinks = ({
  canUseProduct,
  storeId,
  // TODO: Clean up this once finished demo.
  isDisabled,
}: ProductLinksProps): RouteLinks[] => {
  if (!canUseProduct) return [];

  const productLinkProperties = buildProductLinkProperties(storeId);

  return [
    {
      category: {
        ...productLinkProperties,
        // TODO: Clean up this once finished demo.
        disabled: isDisabled,
      },
    },
  ];
};

type ProductLinks = LinkAdapter<ProductLinksProps>;

export const productLinks: ProductLinks = {
  noWorkspace: getProductLinks,
  withWorkspace: getProductLinks,
};

import { RouteLinks } from '~anyx/shared/utils';

import {
  accountLinks,
  analyticsLinks,
  shippingLinks,
  crmLinks,
  integrationLinks,
  inventoryLinks,
  workflowLinks,
  helperLinks,
  homeLinks,
  orderLinks,
  productLinks,
  aiLinks,
} from './route-links';
import { WithWorkspaceLinkProps } from './types';

export const getWorkspaceLinks = ({
  workspaceAccountId,
  accountId,
  isCrmEnabled,
  chats,
  channels,
  isAdmin,
  canSeeUserList,
  canUseOrder,
  canUseAnalytics,
  canSeeInventoryStockValue,
  canUseCrm,
  canUseDatasource,
  canUseInventory,
  canUseProduct,
  canUseShipping,
  canUseWorkflow,
  canSeeAi,
  storeId,
}: WithWorkspaceLinkProps): RouteLinks[] => {
  return [
    // Home
    ...homeLinks.withWorkspace({ canUseAnalytics, storeId }),
    ...aiLinks.withWorkspace({ canSeeAi, storeId }),
    // Analytics
    ...analyticsLinks.withWorkspace({
      canUseAnalytics,
      canSeeInventoryStockValue,
      channels,
      storeId,
    }),
    // Order
    ...orderLinks.withWorkspace({ canUseOrder, storeId, isDisabled: canSeeAi }),
    // Shipping
    ...shippingLinks.withWorkspace({
      canUseShipping,
      accountId,
      storeId,
      isDisabled: canSeeAi,
    }),
    // Product
    ...productLinks.withWorkspace({ canUseProduct, storeId, isDisabled: canSeeAi }),
    // Inventories
    ...inventoryLinks.withWorkspace({ canUseInventory, isAdmin, storeId, isDisabled: canSeeAi }),
    // CRM
    ...crmLinks.withWorkspace({
      canUseCrm,
      storeId,
      isCrmEnabled,
      chats,
      isDisabled: canSeeAi,
    }),
    // Workflow
    ...workflowLinks.withWorkspace({
      storeId,
      canUseWorkflow,
      isDisabled: canSeeAi,
    }),
    //Integration
    ...integrationLinks.withWorkspace({ canUseDatasource, storeId, isDisabled: canSeeAi }),
    // Account
    ...accountLinks.withWorkspace({
      storeId,
      workspaceAccountId,
      canSeeUserList,
      isDisabled: canSeeAi,
    }),
    // Help
    ...helperLinks.withWorkspace(),
  ];
};

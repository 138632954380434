import { IntegrationRoutePath } from '~anyx/common/routing';
import { GitIcon } from '~anyx/shared/icons';
import { RouteLinks } from '~anyx/shared/utils';

import { LinkAdapter } from '../types';

import { InactiveStorePill } from './inactive-store-pill';

export type WithWorkspaceIntegration = {
  canUseDatasource: boolean;
  storeId?: string;
  // TODO: Clean up this once finished demo.
  isDisabled?: boolean;
};

/*
 *
 * Get integration Links when the workspace is selected
 * */
const getIntegrationWithWorkspaceLinks = ({
  canUseDatasource,
  storeId,
  // TODO: Clean up this once finished demo.
  isDisabled,
}: WithWorkspaceIntegration): RouteLinks[] => {
  if (!canUseDatasource) return [];

  return [
    {
      category: {
        type: 'link',
        name: {
          key: 'shared.entity.integration',
          options: { count: 1, ns: 'shared' },
        },
        aside: !isDisabled ? <InactiveStorePill /> : undefined,
        icon: (props) => <GitIcon {...props} />,
        path: IntegrationRoutePath({ storeId, singleStoreMode: true }).list().path,
        activePath: IntegrationRoutePath({ singleStoreMode: true }).list().pattern,
      },
    },
  ];
};

type IntegrationLinks = LinkAdapter<WithWorkspaceIntegration>;

export const integrationLinks: IntegrationLinks = {
  noWorkspace: () => [],
  withWorkspace: getIntegrationWithWorkspaceLinks,
};

import { AccountRoutePath, StoreRoutePath, UserRoutePath } from '~anyx/common/routing';
import { BuildingIcon, StoreIcon, UserIcon } from '~anyx/shared/icons';
import { RouteLink, RouteLinks } from '~anyx/shared/utils';

import { LinkAdapter } from '../types';

type GetUserListLinksProps = {
  canSeeUserList?: boolean;
  storeId?: string;
};

type BuildStoreLinkWithWorkspace = {
  storeId?: string;
};

type NoWorkspaceAccountProperties = {
  showPathToList: boolean;
  workspaceAccountId?: string;
};

type AccountPropertiesWithWorkspace = {
  workspaceAccountId?: string;
  storeId?: string;
};

export type NoWorkspaceAccount = {
  canSeeUserList: boolean;
  showPathToList: boolean;
  workspaceAccountId?: string;
};

export type WithWorkspaceAccount = {
  storeId?: string;
  workspaceAccountId?: string;
  canSeeUserList?: boolean;
  // TODO: Clean up this once finished demo.
  isDisabled?: boolean;
};

const defaultStoreLink: RouteLink = {
  type: 'link',
  name: {
    key: 'shared.entity.store',
    options: { count: 2, ns: 'shared' },
  },
  icon: (props) => <StoreIcon {...props} />,
  path: StoreRoutePath().list().path,
  activePath: StoreRoutePath().root().pattern,
};

/*
 *
 * Build Store Link
 * When a workspace is selected
 * */
const buildStoreLink = (_: BuildStoreLinkWithWorkspace): RouteLink => {
  return {
    ...defaultStoreLink,
    // For workspace links, master data store id is exactly the workspace data store id.
    path: StoreRoutePath().edit().path,
    activePath: StoreRoutePath({ workspaceStoreId: null }).root().pattern,
  };
};

/*
 *
 * Get user list if it's allowed
 * */
const buildUserListLinks = ({ canSeeUserList, storeId }: GetUserListLinksProps): RouteLink[] => {
  if (!canSeeUserList) return [];

  return [
    {
      type: 'link',
      name: {
        key: 'shared.entity.user',
        options: { count: 2, ns: 'shared' },
      },
      path: UserRoutePath({ workspaceStoreId: storeId }).list().path,
      activePath: UserRoutePath({ workspaceStoreId: null }).root().pattern,
    },
  ];
};

/*
 *
 * Build properties for account tab
 * When no workspace is selected
 * */
const buildNoWorkspaceAccountProperties = ({
  showPathToList,
  workspaceAccountId,
}: NoWorkspaceAccountProperties): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.account',
    options: { count: 2, ns: 'shared' },
  },
  icon: (props) => <UserIcon {...props} />,
  path: showPathToList
    ? AccountRoutePath().list().path
    : AccountRoutePath().edit({ accountId: workspaceAccountId }).path,
  activePath: AccountRoutePath().root().pattern,
});

/*
 *
 * Build properties of account tab
 * When a worspace is selected
 * */
const buildAccountWithWorkspaceProperties = ({
  workspaceAccountId,
}: AccountPropertiesWithWorkspace): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.account',
    options: { count: 1, ns: 'shared' },
  },
  icon: (props) => <BuildingIcon {...props} />,
  path: AccountRoutePath().edit({ accountId: workspaceAccountId }).path,
  activePath: AccountRoutePath({ workspaceStoreId: null }).root().pattern,
});

const accountProperties = {
  noWorkspace: buildNoWorkspaceAccountProperties,
  withWorkspace: buildAccountWithWorkspaceProperties,
};

/*
 *
 * Get account links when no workspace is selected
 * */
const getAccountNoWorkspaceLinks = ({
  showPathToList,
  canSeeUserList,
  workspaceAccountId,
}: NoWorkspaceAccount): RouteLinks[] => {
  const properties = accountProperties.noWorkspace({
    showPathToList,
    workspaceAccountId,
  });
  const userListLinks = buildUserListLinks({ canSeeUserList });

  return [
    {
      category: {
        ...properties,
        children: [defaultStoreLink, ...userListLinks],
      },
    },
  ];
};

/*
 *
 * Get account links when the workspace is selected
 * */
const getAccountWithWorkspaceLinks = ({
  workspaceAccountId,
  storeId,
  canSeeUserList,
  // TODO: Clean up this once finished demo.
  isDisabled,
}: WithWorkspaceAccount): RouteLinks[] => {
  const properties = accountProperties.withWorkspace({ workspaceAccountId, storeId });
  const storeLink = buildStoreLink({ storeId });
  const userListLinks = buildUserListLinks({ canSeeUserList, storeId });

  return [
    {
      category: {
        ...properties,
        children: [storeLink, ...userListLinks],
        // TODO: Clean up this once finished demo.
        disabled: isDisabled,
      },
    },
  ];
};

type AccountLinks = LinkAdapter<NoWorkspaceAccount, WithWorkspaceAccount>;

export const accountLinks: AccountLinks = {
  noWorkspace: getAccountNoWorkspaceLinks,
  withWorkspace: getAccountWithWorkspaceLinks,
};

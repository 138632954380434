import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Accordion, NavLink } from '@any-ui-react/core';

import { RouteCategory } from '~anyx/shared/utils';

import { navItemStyles } from './styles';

export const CategoryLink = ({
  isActive,
  category,
  onLinkClick,
}: {
  activePath?: string;
  isActive: boolean;
  category: RouteCategory;
  onLinkClick: () => void;
}) => {
  const { t } = useTranslation();
  const classes = navItemStyles({ active: isActive });
  const navigate = useNavigate();

  return (
    // We use Accordion here to to simplify selection toggle
    <Accordion.Item value={category.name.key}>
      <Accordion.Control>
        <NavLink
          classNames={{
            root: classes.root(),
            section: classes.icon(),
            label: classes.title(),
          }}
          active={isActive}
          key={category.name.key}
          onClick={() => {
            // TODO: Clean up this once finished demo.
            if (category.disabled) return;
            onLinkClick();
            navigate(category.path ?? '');
          }}
          leftSection={category.icon?.({
            className: classes.icon(),
          })}
          label={t(category.name.key, category.name.options || {})}
          rightSection={category.aside}
        />
      </Accordion.Control>
    </Accordion.Item>
  );
};

import {
  CourierRoutePath,
  PickupRoutePath,
  SettingsRoutePath,
  ShipmentRoutePath,
  ShipperRoutePath,
} from '~anyx/common/routing';
import { LogisticFeatureFlag } from '~anyx/shared/graphql';
import { EarthIcon, PlatformCartIcon } from '~anyx/shared/icons';
import { RouteLink, RouteLinks } from '~anyx/shared/utils';

import { LinkAdapter } from '../types';

type BuildShippingSettingLink = {
  showPathToList: boolean;
  accountId?: string;
  storeId?: string;
};

export type NoWorkspaceShipping = BuildShippingSettingLink & {
  highestLogisticPermission?: LogisticFeatureFlag;
  canUseShipping: boolean;
};

export type WithWorkspaceShipping = {
  accountId?: string;
  canUseShipping: boolean;
  storeId?: string;
  // TODO: Clean up this once finished demo.
  isDisabled?: boolean;
};

const buildShippingProperties = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.shipping',
    options: { count: 2, ns: 'shared' },
  },
  icon: (props) => <EarthIcon {...props} />,
  path: ShipmentRoutePath({ workspaceStoreId: storeId }).list().path,
  activePath: ShipmentRoutePath({ workspaceStoreId: null }).root().pattern,
});

const buildShipperAddressesLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.shipperAddress',
    options: { count: 2, ns: 'shared' },
  },
  path: ShipperRoutePath({ workspaceStoreId: storeId }).list().path,
  activePath: ShipperRoutePath({ workspaceStoreId: null }).root().pattern,
});

const buildPickupLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.pickup',
    options: { count: 2, ns: 'shared' },
  },
  icon: (props) => <PlatformCartIcon {...props} />,
  path: PickupRoutePath({ workspaceStoreId: storeId }).list().path,
  activePath: PickupRoutePath({ workspaceStoreId: null }).root().pattern,
});

const buildCourierLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.courier',
    options: { count: 2, ns: 'shared' },
  },
  icon: (props) => <PlatformCartIcon {...props} />,
  path: CourierRoutePath({ workspaceStoreId: storeId }).list().path,
  activePath: CourierRoutePath({ workspaceStoreId: null }).root().pattern,
});

/*
 *
 * Build shipping settings link depending
 * on if it should show path to list or Shipping settings
 * */
const buildShippingSettingLink = ({
  showPathToList,
  accountId,
  storeId,
}: BuildShippingSettingLink): RouteLink => {
  return {
    type: 'link',
    name: {
      key: 'shared.entity.shippingSetting',
      options: { count: 2, ns: 'shared' },
    },
    path: showPathToList
      ? SettingsRoutePath({ workspaceStoreId: storeId }).list().path
      : SettingsRoutePath({ workspaceStoreId: storeId }).edit({ accountId }).path,
    activePath: SettingsRoutePath({ workspaceStoreId: null }).root().pattern,
  };
};

/*
 *
 * Get Shipping links when no workspace is selected
 * */
const getShippingNoWorkspaceLinks = ({
  canUseShipping,
  highestLogisticPermission,
  ...props
}: NoWorkspaceShipping): RouteLinks[] => {
  if (!canUseShipping) return [];

  const shippingSettingLink = buildShippingSettingLink(props);
  const shippingProperties = buildShippingProperties();
  const pickupLink = buildPickupLink();
  const shipperAddressesLink = buildShipperAddressesLink();
  const courierLink = buildCourierLink();

  return [
    {
      category: {
        ...shippingProperties,
        children: [
          shippingSettingLink,
          pickupLink,
          ...(highestLogisticPermission ? [shipperAddressesLink, courierLink] : []),
        ],
      },
    },
  ];
};

/*
 *
 * Get Shipping Links when the workspace is selected
 * */
const getShippingWithWorkspaceLinks = ({
  accountId,
  canUseShipping,
  storeId,
  // TODO: Clean up this once finished demo.
  isDisabled,
}: WithWorkspaceShipping): RouteLinks[] => {
  if (!canUseShipping) return [];

  const shippingSettingLink = buildShippingSettingLink({
    accountId,
    showPathToList: false,
    storeId,
  });

  const shippingProperties = buildShippingProperties(storeId);
  const shipperAddressesLink = buildShipperAddressesLink(storeId);
  const pickupLink = buildPickupLink(storeId);
  const courierLink = buildCourierLink(storeId);

  return [
    {
      category: {
        ...shippingProperties,
        children: [shippingSettingLink, shipperAddressesLink, pickupLink, courierLink],
        // TODO: Clean up this once finished demo.
        disabled: isDisabled,
      },
    },
  ];
};

type ShippingLinks = LinkAdapter<NoWorkspaceShipping, WithWorkspaceShipping>;

export const shippingLinks: ShippingLinks = {
  noWorkspace: getShippingNoWorkspaceLinks,
  withWorkspace: getShippingWithWorkspaceLinks,
};
